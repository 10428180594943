export default {
	data: () => ({
		langEs: {
			btnPath: {
				home: 'Inicio',
				category: 'Cursos',
			},
			skillTitle: {
				text: 'Ventajas del ',
				color: 'curso'
			},
			courseTitle: {
				text: 'Curso',
				color: ' Contenido'
			},
			courseDesc: 'Lecturas online, tareas prácticas, talleres y master classes te están esperando',
			chapters: 'Teoría',
			modules: 'Vídeos prácticos',
			priceTitle: 'Precio',
			buy: 'Compra un curso',
			subscription: 'Revista online',
			login: 'Regístrate ahora',
			SeeTheProgram: 'Ver el programa',
			modalBuyCourse: {
				title: 'Comprar curso',
				name: 'Nombre',
				phone: 'Teléfono',
				modeBuy: 'Método de pago',
				modalBtnBuy: 'Comprar',
				buy: 'Tarjeta',
				conditions: 'Aceptar términos y condiciones',
				terms: 'Términos de uso'
			}
		}
	})
}
